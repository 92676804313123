import React, {FC, useState} from 'react';
import {Box} from '@mui/material';
import Header from 'Components/Organisms/Header';
import Footer from 'Components/Organisms/Footer';
import DropMenu from 'Components/Molecules/DropMenu';
import {ErrorProvider} from '../ErrorContext';

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const GenericTemplate:FC<GenericTemplateProps> = ({children}) => {
  const [dropMenuOpen, setDropMenuOpen] = useState(false);
  const toggleDropMenu = () => setDropMenuOpen(!dropMenuOpen);

  return (
    <>
      <ErrorProvider>
      <Header onOpen={toggleDropMenu} />
      <DropMenu open={dropMenuOpen} onClose={toggleDropMenu}/>
      <Box sx={{minHeight:'100%'}}>
        {children}
      </Box>

      <Footer/>
      </ErrorProvider>
    </>
  )
}
export default GenericTemplate;