import React, {FC} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Config from 'Types/config';

type Props = {
    onOpen: () => void
}
const Header: FC<Props> = ({onOpen}) => (
    <Box sx={{flexGrow: 1,m:'0 0 30px'}}>
        <AppBar position="static">
            <Toolbar variant="dense">
                {onOpen && (
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}} onClick={onOpen}>
                        <MenuIcon sx={{color: '#fff'}} />
                    </IconButton>
                )}
                <Typography sx={{color: '#fff'}} variant="h6" color="inherit" component="div">
                    {Config.applicationTitle}
                </Typography>
            </Toolbar>
        </AppBar>
    </Box>
)

export default Header
