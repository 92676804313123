import {FC, useEffect, useState} from 'react';
import {Alert, Snackbar} from '@mui/material';

type IMessage = {
  content: string

}
const Message: FC<IMessage> = ({content}) => {
  const [isView,setView] = useState<boolean>(false)

  useEffect(() => {
    if(content.length > 0) setView(true);
  }, [content]);

  const handleClose = () => {
    setView( false)
  }

  return (
    <>
      {content.length > 0 ? (<Snackbar open={isView} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose}>
          {content}
        </Alert>
      </Snackbar>) : ''}
    </>
  )
}


export default Message