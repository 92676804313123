import React, { FC } from 'react';
import useFetchcurriculums from 'Hooks/useFetchCurriculums';
import { Alert, Grid, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import DefaultButton from '../atom/Buttons/DefaultButton';
import BackdropLoading from '../comm/BackDrop';
import Box from '../atom/Box';
import CurriculumCell from '../comm/CurritulumCell';
import CalendarStatus from '../comm/CalendarStatus';
import BookingCompleted from '../Organisms/BookingCompleted';

const SAlert = styled(Alert)(() => ({
	width:'100%',
	margin: '1em 0',
	padding: '5px',
	position: 'sticky',
	top: 0,
	'& .MuiAlert-message': {
		width: '100%'
	}
}))

const Curriculums: FC = () => {
	const ref = React.createRef<HTMLDivElement>()
	const isMdScreen = useMediaQuery('(min-width:600px)');
	const {
		user,
		curriculums,
		loading,
		calendarDate,
		handleCalendarDown,
		handleCalendarUP,
		status,
		handleCheangeCurritulums,
		handleGoInput,
		handleSubmut,
		weeks,
		isBookOver,
		isPost
	} = useFetchcurriculums()
	const today = new Date()
	const endMounthDay = new Date(calendarDate.getFullYear(),calendarDate.getMonth()+1,0)
	const serviceProvision = user?.service_provision === 0 ? endMounthDay.getDate() - 8 : Number(user?.service_provision)
	const bookCount = curriculums.filter(value => value.is_book).length
	const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']
	const SButtonGrid = styled(Grid)(() => ({
		position: 'sticky',
		bottom: isMdScreen ? '3em' : '3em',
		left: 0,
		backgroundColor:'rgba(255, 255, 255, 0.9)',
		width: '100%',
	}))
	const handleFormClick = () => {
		ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
		handleSubmut()

	}


	const handleFormBack = () => {
		ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
		handleGoInput()

	}


	if (loading) return <><BackdropLoading showBackdrop={loading}/></>

	return (
		 <>
			<Box>
				<Grid container alignItems="center" ref={ref}>
					<Grid item xs={12} md={10} sx={{textAlign:{'xs':'center','md':'left'}}}>
						<Typography variant="h5">
							{calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 カリキュラム
						</Typography>
					</Grid>
					{status ==='input' && curriculums.filter(v=>v.is_edit).length === 0 &&
					<Grid item xs={12} md={2} sx={{mt:{xs:2,md:0,textAlign:{'xs':'center','md':'right'}}}}	 justifyContent="center" >

							<DefaultButton name="preMonth" onClick={handleCalendarDown} sx={{display: today.getMonth() !== calendarDate.getMonth() ? 'inline' : 'none'}} label="当月"/>

							<DefaultButton name="nextMonth" onClick={handleCalendarUP} sx={{display: today.getMonth() !== calendarDate.getMonth() ? 'none' : 'inline'}} label="翌月"/>
					</Grid>}
				</Grid>
			</Box>
				 {weeks.length ===0 ?
				 <SAlert sx={{textAlign:'center'}}>
				 <Typography variant="h5">カリキュラムが見つかりませんでした。</Typography>
				 </SAlert>
				 :
				 <>
			<CalendarStatus status={status} isAlert={{isBookOver}} serviceProvision={serviceProvision || 0 } bookCount={bookCount}/>
			 <BookingCompleted status={status} curriculums={curriculums} />
			 {status === 'input' && <table id="calendar" style={{width: '100%', border: '1px jsoild #eee', borderSpacing: '0',marginBottom:'1em'}}>
				<tbody>
				{weeks.map((week, key) =>
					<tr key={`row-${weekDay[key]}`}>
						{week.map((day) =>
								<CurriculumCell
									key={`row-${weekDay[key]}-${day.lecture_date.getMonth()}-${day.lecture_date.getDate()}`}
									status={status}
									isFlex={false}
									curriculum={day}
									handleChangeCurriculum={handleCheangeCurritulums}
									currentMonth={calendarDate.getMonth()}/>
						)}
					</tr>
				)}
				</tbody>
			</table>}
			{status !== 'send' &&
        <SButtonGrid container alignItems="center">
          <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>
            <Stack direction="row" spacing={2} justifyContent="center">
							{status === 'view' && <DefaultButton onClick={handleFormBack} label="選択画面に戻る"/>}

              <DefaultButton name="go" onClick={handleFormClick} disabled={isPost}
                             label={status === 'input' ? '確認' : '送信'}/>
            </Stack>

          </Grid>
        </SButtonGrid>
			}
			</>}
		</>
	)
};

export default Curriculums
