import {ICurriculum} from '../Types/Response';
import useCurriculum from './useCurriculum';

export type TWeeks = Array<Array<ICurriculum>>

const useCalendarCell = () => {
    const {newCurriculum} = useCurriculum()

    const createCurriculumCalendar = (curriculums: ICurriculum[]): TWeeks => {
        const weeks: TWeeks = []

        const current = curriculums.concat()
        if(current.filter(value => value.lecture_id>1).length === 0) return weeks

        const sDay = current[0].lecture_date.getDay()
        const startDay = new Date(current[0].lecture_date)
        for (let i = 0; i <= sDay - 1; i+=1) {
            startDay.setDate(startDay.getDate() - 1)
            current.unshift(newCurriculum(new Date(startDay)))
        }
        const eDay = curriculums[curriculums.length - 1].lecture_date.getDay() + 1
        const endDay = new Date(current[current.length - 1].lecture_date)
        // eslint-disable-next-line no-plusplus
        for (let i = eDay; i < 7; i++) {
            endDay.setDate(endDay.getDate() + 1)
            current.push(newCurriculum(new Date(endDay)))
        }
        // console.log(current)
        current.forEach((value, index) => {
            if (index === 0 || index % 7 === 0) {
                weeks.push([])
            }
            weeks[weeks.length - 1].push(value)
        })
console.info(weeks)

        return weeks
    }

    return {createCurriculumCalendar}
}
export default useCalendarCell
