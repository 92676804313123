import React, {createContext, Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import Message from './comm/Message';

type ContextType = {
    message: string;
    handleSetMessage: (message: string) => void;
    setMessage?: Dispatch<SetStateAction<string>>;
};
export const MessageContext = createContext({} as ContextType);

export const useMessage = () => {
    const [message, setMessage] = useState<string>('');

    const handleSetMessage = useCallback((m: string) => {
        setMessage(m);
    }, []);
    const setContextError = (userMessage: string) => {
        setMessage(userMessage);
    };

    const setContextErrorDone = () => {
        setMessage('');
    };

    return {
        message,
        handleSetMessage,
        setContextErrorDone,
        setContextError
    };
};
type Props = {
    children: React.ReactNode
}
export const MessageProvider: React.FC<Props> = ({children}) => {
    const {message, handleSetMessage} = useMessage();
    const value = useMemo(() => ({message, handleSetMessage}), [handleSetMessage, message])

    return (
        <MessageContext.Provider value={value}>
            <Message content={message}/>
            {children}
        </MessageContext.Provider>
    );
};