import {ICurriculum} from '../Types/Response';

const serviceCloseColor = '#7c7c7c'
const serviceOnColor = '#2A81B7'
const cancelColor = '#563645'
const cancelOnColor = '#586496'
const bookingDayLimit =5
const serviceLimitDate = new Date()
serviceLimitDate.setDate(serviceLimitDate.getDate()+bookingDayLimit)

const useCurriculumCell = () => {
    const isPastDate = (inputDate:Date) => {
        const currentDate = new Date();

        return inputDate < currentDate;
    };

    const isBooking = (curriculum: ICurriculum) => {
        const day = new Date(curriculum.lecture_date)
        if (curriculum.lecture_id === 1) return false

        return serviceLimitDate < day && !isPastDate(day);
    }

    const isServiceClose = (curriculum: ICurriculum) => {
        if (serviceLimitDate.getDate() === 1) serviceLimitDate.setDate(serviceLimitDate.getDate() - 1)

        return serviceLimitDate > curriculum.lecture_date


    }

    const serviceLabel = (curriculum: ICurriculum) => {
        const lectureDate = new Date(curriculum.lecture_date)
        // // const isToday = today.getFullYear() === LectureDate.getFullYear() && today.getMonth() === LectureDate.getMonth() && today.getDate() === LectureDate.getDate()

        if (isServiceClose(curriculum) && isPastDate(lectureDate) && curriculum.is_book) return 'サービス提供終了'
        if (isServiceClose(curriculum)) return '予約受付終了'
        if (isServiceClose(curriculum)) return ' '
        if (curriculum.book_count > curriculum.service_limit && curriculum.book_rank > curriculum.service_limit && curriculum.is_book && !curriculum.is_edit) return 'キャンセル待ち受付済み'
        if (curriculum.book_count > curriculum.service_limit && curriculum.book_rank > curriculum.service_limit && curriculum.is_book && curriculum.is_edit) return 'キャンセル待ち受付済み'
        if (curriculum.book_count > curriculum.service_limit && curriculum.book_rank > curriculum.service_limit && curriculum.is_book && !curriculum.edit_book && curriculum.is_edit) return 'キャンセル待ち解除'
        if (curriculum.book_count > curriculum.service_limit && curriculum.book_rank > curriculum.service_limit && !curriculum.is_book && !curriculum.edit_book && curriculum.is_updated) return 'キャンセル待ち解除'
        if (curriculum.book_rank === 0 && curriculum.book_count >= curriculum.service_limit && !curriculum.is_book) return 'キャンセル待ち受付中'

        if (curriculum.is_book) return '予約受付済み'


        return '予約受付中'

    }

    const labelText = (curriculum:ICurriculum) => serviceLabel(curriculum)

    const serviceColor = (curriculum:ICurriculum) => {
        if (labelText(curriculum) === 'サービス提供終了' || labelText(curriculum) === '予約受付終了') return serviceCloseColor
        if (labelText(curriculum).includes('キャンセル待ち受付中')) return cancelColor
        if (labelText(curriculum).includes('キャンセル待ち受付済み')) return cancelOnColor

        return serviceOnColor
    }


    const label = (curriculum: ICurriculum) => {
        if (labelText(curriculum).includes('キャンセル待ち受付中') && curriculum.is_updated) return 'キャンセル待ち予約しました。'
        if (labelText(curriculum).includes('キャンセル待ち受付中') && !curriculum.is_updated) return 'キャンセル待ち予約'
        if (labelText(curriculum).includes('キャンセル待ち受付済み') && curriculum.is_updated) return 'キャンセル待ち解除しました。'
        if (labelText(curriculum).includes('キャンセル待ち受付済み') ) return 'キャンセル待ち解除'

        if (labelText(curriculum).includes('予約受付済み') && curriculum.is_updated) return '予約解除しました。'
        if (labelText(curriculum).includes('予約受付済み') && !curriculum.is_updated) return '予約解除'

        if (curriculum.is_updated) return '予約しました。'

        return '予約'
    }
    const chipColor = (curriculum: ICurriculum) => {
        if (curriculum.service_limit <= curriculum.book_count && curriculum.service_limit < curriculum.book_rank) return 'warning'
        if (curriculum.is_book) return 'primary'

        return 'secondary'
    }
    const chipStyle = (curriculum: ICurriculum) => curriculum.is_edit ? 'filled' : 'outlined'
    const cellStyle = (curriculum:ICurriculum) => !isBooking(curriculum) ? {cursor: 'auto'} : {cursor: 'pointer'}

    return {isBooking, isServiceClose, serviceLabel,isPastDate, serviceColor, cellStyle, chipColor, chipStyle, label}
}
export default useCurriculumCell
