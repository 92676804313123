import React, {createContext, Dispatch, FC, SetStateAction, useState} from 'react';
import TOffice from '../Types/TOffice';

interface AuthUserProviderProps {
    children: React.ReactNode;
}

type OperationType = {
    isLoggedIn: boolean;
    setIsLoggedIn: Dispatch<SetStateAction<boolean>>
    office:TOffice|null
    setOffice:Dispatch<SetStateAction<TOffice|null>>
}

export const AuthOperationContext = createContext<OperationType>({
    isLoggedIn: false,
    setIsLoggedIn:()=> null,
    office:null,
    setOffice:() =>null
});

const AuthUserProvider: FC<AuthUserProviderProps> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [office,setOffice] = useState<TOffice|null>(null)

    return (
        <AuthOperationContext.Provider value={{ isLoggedIn, setIsLoggedIn,office,setOffice }}>
            { children }
        </AuthOperationContext.Provider>
    );
}

export default AuthUserProvider;
