import React, {FC, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import Header from 'Components/Organisms/Header';
import DropMenu from 'Components/Molecules/DropMenu';
import Footer from 'Components/Organisms/Footer';
import {ErrorProvider} from 'Components/ErrorContext';
import {useNavigate,useLocation} from 'react-router-dom';
import useAuth from '../Hooks/useAuth';
import BackDrop from './comm/BackDrop';

export interface PrivateRouteProps {
    children: React.ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({children}) => {
    const {checkLoginStatus, isLoading} = useAuth()

    const [dropMenuOpen, setDropMenuOpen] = useState(false);
    const toggleDropMenu = () => setDropMenuOpen(!dropMenuOpen);

    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        async function checkStatus() {
            const isLoggedIn = await checkLoginStatus()

            if (!isLoggedIn) {
                const currentUrl = location.pathname
                navigate(`/login?from=${currentUrl}`)
            }
        }

        checkStatus().catch(() =>{navigate('/login')})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return isLoading ?
        <BackDrop showBackdrop={isLoading}/> :
        (
            <ErrorProvider>
                <Header onOpen={toggleDropMenu}/>
                <DropMenu open={dropMenuOpen} onClose={toggleDropMenu}/>
                <Box sx={{m: 2, mb: {md:'10em',xs:'5em'}}}>
                    {/* <button onClick={()=>{logout()}}>logout</button> */}
                    {children}
                </Box>
                <Footer/>
            </ErrorProvider>
        )
};

export default PrivateRoute;
