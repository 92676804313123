import axios, {AxiosRequestConfig} from 'axios';
import useToken from 'Hooks/useToken';
import Conf from 'Types/config';

const useHttp = ()=>{
    const {getToken} = useToken()

    const client = ()=>{
        const token = getToken()

        if(!token) return axios.create()

        const requestConfig: AxiosRequestConfig = {
            baseURL: `${Conf.apiIrl}`,
            headers: {'Authorization': `Bearer ${token}`},
        }

        return axios.create(requestConfig)
    }

    return {client}
}

export default useHttp