import React, {FC, useContext} from 'react'
import {Box, Typography, useMediaQuery} from '@mui/material';
import {AuthOperationContext} from '../AuthUserProvider';

type TTitleProps = {
    text: string | React.ReactElement
};

const Title: FC<TTitleProps> = ({text}) => (
    <Typography
        sx={{textAlign: 'center', fontSize: '80%', mb: '0.5rem'}}
    >{text}</Typography>
);
type TCopyrightProps = {
    year: number,
    company: string
}



const Copyright: FC<TCopyrightProps> = ({year, company}) => (
    <Typography
        style={{textAlign: 'center', fontSize: '30%'}}
    >{`${year} `} | Copyright {company}</Typography>
);

const Footer: FC = () => {

    const {office} = useContext(AuthOperationContext);

    const isMobileDevice = useMediaQuery('(max-width:600px)');

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                paddingY: '0.5rem',
                position: 'fixed',
                bottom: 0,
                left: 0,
                zIndex: 0,
            }}
        >
            {office && (
                <Title
                    text={isMobileDevice ?
                        <a href={`tel:${office.tel}`} style={{color:'#fff',textDecoration:'none'}}>
                            {`${office.name}へのお問い合わせ:${office.tel}`}
                        </a>
                        :
                        `${office.name}へのお問い合わせ先:${office.tel}`
                    }
                />
            )}
            <Copyright
                year={new Date().getFullYear()}
                company="Man to Man Animo株式会社"
            />
        </Box>
    )
}

export default Footer
