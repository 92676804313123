import {useCallback, useContext, useState} from 'react';
import axios from 'axios';
import {TLogin, TLoginResponse} from 'Types/Login';
import Conf from 'Types/config';
import useHttp from './useHttp';
import {AuthOperationContext} from '../Components/AuthUserProvider';
import TOffice from '../Types/TOffice';

interface IIsLogin {
    isLogin: boolean
    office: TOffice
}

const useAuth = () => {
    const {client} = useHttp()
    const [isLoading, setLoading] = useState<boolean>(false)
    const {setIsLoggedIn, setOffice} = useContext(AuthOperationContext)
    // const handleError = useMemo(() => (reason: AxiosError) => {
    //     setLoading(false)
    //     if (reason?.response?.status === 401) {
    //         navigate('/login');
    //     }
    //     if (reason?.response?.status === 500) {
    //         navigate('/error');
    //
    //     }
    // }, [navigate])

    const loginRequest = useCallback(async (email: string, password: string): Promise<TLogin> => {
        try {
            const res = await axios.post<TLoginResponse>(`${Conf.apiIrl}login`, {
                email,
                password
            }, {
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3002',
                    'content-type': 'application/json'
                }
            });

            setIsLoggedIn(true)

            return {isLogin: true, statusCode: 200, errorMessage: '', user: res.data}

        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401 || e.response?.status === 422) {
                    return {
                        isLogin: false,
                        statusCode: 401,
                        errorMessage: 'メールアドレスか、パスワードが違います。',
                        user: {access_token: '', id: 0}
                    }
                }
                if (e.response?.status === 500) {
                    return {
                        isLogin: false,
                        statusCode: 500,
                        errorMessage: 'サーバに不具合があります。',
                        user: {access_token: '', id: 0}
                    }
                }

            }

            return {isLogin: false, statusCode: 200, errorMessage: '。', user: {access_token: '', id: 0}}
        }

    }, [setIsLoggedIn]);
    const checkLoginStatus = async (): Promise<boolean> => {
        setLoading(true);
        const http = client();

        try {
            const res = await http.get<IIsLogin>(`${Conf.apiIrl}home`);
            setLoading(false);

            if (res.data.office) setOffice(res.data.office);

            return true;
        } catch (error) {
            setLoading(false);

            return false;
        }
    };

    const logout = useCallback((): void => {
        console.log('logout')
    }, [])


    return {logout, isLoading, loginRequest, checkLoginStatus}
}
export default useAuth