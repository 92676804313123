import {ICurriculum} from '../Types/Response';

const useCurriculum = ()=> {
  const emptyCurriculum = () =>{
    const curriculum:ICurriculum = {
      id: '',
      book_rank:0,
      lecture_date: new Date(),
      lecture_id: 0,
      is_time: 0,
      name: '',
      title: '',
      file_name: '',
      url: '',
      is_book:false,
      is_edit:false,
      edit_book:false,
      book_count:0,
      service_limit:0,
      is_updated:false,

    }

    return curriculum
  }

  const newCurriculum = (targetDate:Date) =>{
    const curriculum:ICurriculum = emptyCurriculum()

    return {...curriculum,lecture_date:targetDate}
  }

  return {newCurriculum}
}

export default useCurriculum