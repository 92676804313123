import {FC} from 'react';
import {BoxProps, Paper, styled} from '@mui/material';


const SBox = styled(Paper)(({
  textAlign: 'center',
  padding: 20,
}));
const Box: FC<BoxProps> = ({children}) => (
      <SBox>{children}</SBox>
  )
export default Box
