const useToken = () => {
    const setToken = (token: string): void => {
        localStorage.setItem('token', token)

    }
    const removeToken = (): void => {
        localStorage.removeItem('token')

    }
    const getToken = (): string | undefined => {
        const token = localStorage.getItem('token')
        if (token === null) return ''

        return token
    }


    return {setToken,removeToken, getToken}
}
export default useToken