import React, {FC} from 'react';
import {Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import DefaultButton from '../atom/Buttons/DefaultButton';

const Home: FC = () => {
    const navigate = useNavigate();

    const handleGotoPage = (url: string) => navigate(url)

    return (

        <>
            <Typography variant="h5">ダッシュボード</Typography>
          <Typography sx={{textAlign: 'center', my: '1rem'}}>
            <DefaultButton label="カリキュラム予約" onClick={() => handleGotoPage('/curriculums')}/>
          </Typography>
          <Typography sx={{textAlign: 'center', my: '1rem'}}>
            <DefaultButton label="新カレンダー" onClick={() => handleGotoPage('/calendar')}/>
          </Typography>

        </>
    )
}
export default Home
