import {FC} from 'react';
import {
    Grid, styled,
    Typography
} from '@mui/material';
import CellDay from 'Components/atom/CellDay';
import {ICurriculum} from 'Types/Response';
import CellDateHead from 'Components/comm/CellDateHead';
import useCurriculumCell from 'Hooks/useCurriculumCell';
import ServiceChip from 'Components/comm/ServiceChip';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import config from '../../Types/config';
import {TStatus} from '../../Types/BookStatus';

type Props = TableCellProps & {
    status: TStatus,
    curriculum: ICurriculum,
    currentMonth: number,
    handleChangeCurriculum: (cCurriculum: ICurriculum) => void
    isFlex?:boolean
}

type styleProp = {
    cursor: 'pointer' | 'default'
}


export const STypography = styled(Typography)<styleProp>(({cursor}) => ({
    textAlign: 'center',
    padding: '0.5em',
    cursor
}))
const CurriculumCell: FC<Props> = ({status, curriculum, currentMonth, handleChangeCurriculum,isFlex}) => {
    const {isBooking, serviceLabel,isPastDate, isServiceClose,serviceColor, chipStyle, label} = useCurriculumCell()
    const cursor = status === 'input' && !isServiceClose(curriculum) && curriculum.lecture_id > 1 ? 'pointer' : 'default'
    // eslint-disable-next-line consistent-return
    const handleCellClick = () => {
        if (!isBooking(curriculum) || status !== 'input') return null
        handleChangeCurriculum({...curriculum, is_edit: !curriculum.is_edit, edit_book: !curriculum.edit_book})

    }

    return <>
        <CellDay
          isFlex={isFlex}
                currentMonth={currentMonth}
                 isMobileDisplay={currentMonth === curriculum.lecture_date.getMonth()} curriculum={curriculum}>
            {currentMonth === curriculum.lecture_date.getMonth() ?
                <>
                    <Grid container alignItems="center">
                        <Grid item xs={12}
                              sx={(status === 'view' || status === 'send') && !curriculum.is_edit ? {opacity: '1%'} : {}}
                              onClick={() => handleCellClick()}>
                            <CellDateHead date={curriculum.lecture_date}/>
                            <STypography cursor={cursor}>{curriculum.name}</STypography>
                            {curriculum.lecture_id > 1 &&

                                <STypography cursor={cursor} sx={{
                                    borderColor: serviceColor(curriculum),
                                    color: serviceColor(curriculum),
                                    fontSize: '90%',
                                    fontWeight: '1000'
                                }}>{serviceLabel(curriculum)}</STypography>}
                            {curriculum.lecture_id > 1 && isBooking(curriculum) && <>
                                <ServiceChip
                                    variant={chipStyle(curriculum)}
                                    styleCcolor={serviceColor(curriculum)}
                                    label={label(curriculum)}/>

                            </>}
                            {curriculum.lecture_id > 1 && !isPastDate(curriculum.lecture_date) && isServiceClose(curriculum) && <>
                                    {curriculum.is_book ? '予約済み' : ''}

                            </>}
                        </Grid>
                        {curriculum.lecture_id === 7 && curriculum.url &&
                            <Grid item xs={12}><Typography sx={{textAlign:'center',my:'1em'}}><a
                            href={`${config.host}/curriculums/download/${curriculum.id}`}>{curriculum.title}</a>
                            </Typography></Grid>}

                        {curriculum.lecture_id === 7 && !curriculum.url &&
                            <Grid item xs={12}><Typography sx={{textAlign:'center',my:'1em'}}>{curriculum.title}
                            </Typography></Grid>}

                    </Grid>
                </> : null
            }
        </CellDay>
    </>
}
CurriculumCell.defaultProps = {
    isFlex:false,
}
export default CurriculumCell
