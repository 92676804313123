import {FC} from 'react';
import {Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, styled, Typography} from '@mui/material';
import { Done, East} from '@mui/icons-material';
import {useNavigate} from 'react-router';


const HelpBookCell: FC = () => {
    const navigate = useNavigate();
    const handleGotoPage = (url: string) => {
        navigate(url)

    }
    const STypography = styled(Typography)({
        borderColor:'red',
        textAlign:'center'
    })

    return (
        <Paper sx={{width: '80%', mx: 'auto', maxWidth: '100%'}}>
            <MenuList>
                <MenuItem sx={{fontVariant:'bold'}}>
                    <STypography>ログイン・ログアウト</STypography>
                </MenuItem>

                <MenuItem onClick={() => handleGotoPage('login')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>ログインする</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleGotoPage('login')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>ログインアウトする</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>
                <Divider/>
                <MenuItem sx={{fontVariant:'bold'}}>
                    <STypography>カリキュラム</STypography>
                </MenuItem>

                <MenuItem onClick={() => handleGotoPage('login')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>カリキュラムを確認する</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>

                <MenuItem onClick={() => handleGotoPage('bookCell')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>カリキュラムを予約する</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleGotoPage('login')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>カリキュラムをキャンセルする</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleGotoPage('login')}>
                    <ListItemIcon>
                        <Done fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>カリキュラムをキャンセル待ちする</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        <East/>
                    </Typography>
                </MenuItem>
            </MenuList>
        </Paper>
    )
}

export default HelpBookCell