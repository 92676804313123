import {Chip, chipClasses, ChipProps, styled} from '@mui/material';
import {FC} from 'react';

export type StyleChipProps = ChipProps & {styleCcolor: string;};

const ServiceChip: FC<StyleChipProps> = ({variant,label,styleCcolor}) => {
    const SChip = styled(Chip)({
        textAlign: 'center',
        padding: '0.5em 2em',
        color: styleCcolor,
        borderRadius: 10,
        fontSize: '90%',
        borderColor: styleCcolor,
        background:'none',
        cursor:'pointer',
        [`&.${chipClasses.filled}`]: {
            backgroundColor: styleCcolor,
            color:'#fff'
            // color: lightGreen['00']
        },
    });


    return <SChip variant={variant} label={label}/>;
};
export default ServiceChip;
