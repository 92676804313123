import {FC} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from 'react-router';
import {ListItemIcon, ListItemText, ListItem, Drawer, Divider, List, ListItemButton} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '../atom/Box';
import useToken from '../../Hooks/useToken';

type Props = {
    open: boolean;
    onClose: () => void;
};

const DropMenu:FC<Props> = ({open, onClose}) => {
    const {removeToken}=useToken()
    const navigate = useNavigate();
    const handleGotoPage = (url: string) => {
        navigate(url)
        onClose()

    }
    const handleLogout = () => {
      removeToken()
        handleGotoPage('/login')
    }

    return (
        <Drawer open={open} onClose={onClose}>
            <Box sx={{width: 250}} role="presentation">
                <List>

                    <ListItem disablePadding onClick={() => handleGotoPage('/')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ダッシュボード"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => handleGotoPage('/curriculums')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <CalendarMonthIcon/>
                            </ListItemIcon>
                            <ListItemText primary="カリキュラム予約"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => handleLogout()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ログアウト"/>
                        </ListItemButton>
                    </ListItem>
                </List>

                {/*    <List> */}
                {/*      {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
                {/*    <ListItem key={text} onClick={props.onClose}> */}
                {/*  <ListItemIcon> */}
                {/*    {index % 2 === 0 ? <Inbox /> : <Mail />} */}
                {/*  </ListItemIcon> */}
                {/*  <ListItemText primary={text} /> */}
                {/*  </ListItem> */}
                {/* ))} */}
                {/*  </List> */}
                <Divider/>
            </Box>
        </Drawer>
    );
};
export default DropMenu